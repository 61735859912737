// extracted by mini-css-extract-plugin
export var backgroundDarkBlue = "contact-module--backgroundDarkBlue--dZqIK";
export var backgroundGreen = "contact-module--backgroundGreen--qG9m1";
export var backgroundWhite = "contact-module--backgroundWhite--8iO3H";
export var ctaBlock = "contact-module--ctaBlock--9VfJ+";
export var grid = "contact-module--grid--5pbIk";
export var gridBlock = "contact-module--gridBlock--DKgkA";
export var gridBottom = "contact-module--gridBottom--iFh0J";
export var inner = "contact-module--inner--BAYVv";
export var layer = "contact-module--layer--eop0I";
export var paragraph = "contact-module--paragraph--xHfuI";
export var paragraphShort = "contact-module--paragraphShort--pS579";
export var paragraphSmall = "contact-module--paragraphSmall--ppBlc";
export var presenation = "contact-module--presenation--M2ZBi";
export var sectionPaddingBottom = "contact-module--sectionPaddingBottom--F8keP";
export var sectionPaddingTop = "contact-module--sectionPaddingTop--aRdwy";
export var spacingTop = "contact-module--spacingTop--ON0CP";
export var subheading = "contact-module--subheading--knfua";
export var subheadingShort = "contact-module--subheadingShort--M+3xu";
export var textBlack = "contact-module--textBlack--3X3VT";
export var textBlock = "contact-module--textBlock--SYI+b";
export var textCenter = "contact-module--textCenter--4SF0U";
export var textWhite = "contact-module--textWhite--my54B";