import React, { useState } from "react";
import { TranslationService } from "../../../services/translation.service";
import { TranslationProvider } from "@/src/contexts/translation.context";
import { Helmet } from "react-helmet";
import Header from "@/src/components/header/header";
import * as styles from "./contact.module.scss";
import Footer from "@/src/components/footer/footer";
import { StaticImage } from "gatsby-plugin-image";

export default function ContactPage({ pageContext }: any) {
  const [translation] = useState(new TranslationService(pageContext));
  console.log(pageContext);

  return (
    <>
      <TranslationProvider value={translation}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{translation.translate("contact.title")}</title>
          <meta
            name="description"
            content={translation.translate("contact.description")}
          />
          <meta http-equiv="content-language" content={translation.lang} />
          <html className="unauthenticated"></html>
        </Helmet>

        <Header unauthenticated={true} pageContext={pageContext}></Header>

        <main>
          <div className={`${styles.textBlock} ${styles.backgroundGreen}`}>
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <h2 className={`${styles.subheading} ${styles.textBlack}`}>
                {translation.translate("contact.block1.heading")}
              </h2>
              <p className={`${styles.paragraph}`}>
                {translation.translate("contact.block1.line1")}
              </p>
              <p className={`${styles.paragraph}`}>
                {translation.translate("contact.block1.line2")}
              </p>
            </div>
          </div>

          <div
            className={`${styles.textBlock} ${styles.sectionPaddingTop} ${styles.backgroundWhite}`}
          >
            <div className={styles.inner}>
              <h2 className={`${styles.subheading}`}>
                {translation.translate("contact.block2.heading")}
              </h2>
              <p className={styles.paragraph}>
                {translation.translate("contact.block2.line1")}
              </p>
            </div>
          </div>

          <div
            className={`${styles.textBlock} ${styles.sectionPaddingTop} ${styles.backgroundWhite}`}
          >
            <div className={styles.inner}>
              <h2 className={`${styles.subheading}`}>
                {translation.translate("contact.block3.heading")}
              </h2>
              <p className={styles.paragraph}>
                {translation.translate("contact.block3.line1")}
              </p>
            </div>
          </div>

          <div className={`${styles.gridBlock} ${styles.backgroundWhite}`}>
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <div className={`${styles.grid}`}>
                <div>
                  <h2 className={`${styles.subheading}`}>
                    {translation.translate("contact.block4.heading1")}
                  </h2>
                  <p className={styles.paragraph}>
                    {translation.translate("contact.block4.line1")}
                  </p>
                </div>
                <div>
                  <h2 className={`${styles.subheading}`}>
                    {translation.translate("contact.block4.heading2")}
                  </h2>
                  <p className={styles.paragraph}>
                    {translation.translate("contact.block4.line2")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.gridBlock} ${styles.backgroundGreen}`}>
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <div className={`${styles.grid}`}>
                <div>
                  <p className={styles.presenation}>
                    {translation.translate("contact.presentation.line1")}
                  </p>
                </div>
                <div>
                  <StaticImage
                    src="../../../../assets/images/ann.jpeg"
                    alt="Evi's founder Ann"
                    className={styles.staticImage}
                  />
                </div>
                <div className={`${styles.gridBottom}`}>
                  <p className={styles.presenation}>
                    {translation.translate("contact.presentation.line2")}
                  </p>
                  <p className={styles.presenation}>
                    {translation.translate("contact.presentation.line3")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            id="cta-contact-block"
            className={`${styles.backgroundDarkBlue} ${styles.ctaBlock}`}
          >
            <div
              className={`${styles.inner} ${styles.sectionPaddingBottom} ${styles.sectionPaddingTop}`}
            >
              <h2
                className={`${styles.subheading} ${styles.textCenter} ${styles.textWhite}`}
              >
                {translation.translate("contact.block5.heading")}
              </h2>
              <p
                className={`${styles.paragraph} ${styles.textCenter} ${styles.paragraphSmall} ${styles.paragraphShort} ${styles.textWhite} ${styles.spacingTop}`}
              >
                {translation.translate("contact.block5.line1")}
              </p>
              <a
                className={`${styles.textWhite}`}
                href="mailto:info@evidencediary.com"
                target="_blank"
              >
                {translation.translate("contact.mail")} info@evidencediary.com
              </a>
            </div>
          </div>
        </main>

        <Footer pageContext={pageContext}></Footer>
      </TranslationProvider>
    </>
  );
}
